<template>
  <div :class="className">
    {{ value }}
  </div>
</template>
<script>
export default {
  props: ['value', 'className'],
}
</script>
<style scoped>

</style>